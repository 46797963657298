<template>
  <div>
    <navbar />
    <main>

      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover Fondo-Contrato"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  Adquirí tu servicio
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Contrate el servicio que más cumpla tus necesidades. 
                  Recuerda que en cualquier momento puedes cambiar de plan si ya lo has adquirido y continúa  con tus actividades.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="divPlanes Fondo-White relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i :class="`${PlanUno.icono}`"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ PlanUno.nombre}}
                  </h6>
                  <p class="m-8">
                    <small class="text-2xl">$ </small>
                    <strong class="text-4xl">{{ PlanUno.precio }}</strong>
                  </p>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanUno.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="divPlanes Fondo-White relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                     <i :class="`${PlanDos.icono}`"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ PlanDos.nombre}}
                  </h6>
                  <p class="m-8">
                    <small class="text-2xl">$ </small>
                    <strong class="text-4xl">{{ PlanDos.precio }}</strong>
                  </p>
                  <p class="mt-2 mb-4 text-blueGray-500">
                   <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanDos.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="divPlanes Fondo-White relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                  >
                    <i :class="`${PlanTres.icono}`"></i>
                  </div>
                  <h6 class="text-xl font-semibold">
                    {{ PlanTres.nombre}}
                  </h6>
                  <p class="m-8">
                    <small class="text-2xl">$ </small>
                    <strong class="text-4xl">{{ PlanTres.precio }}</strong>
                  </p>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanTres.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pb-20 relative block bg-blueGray-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">
                ¿Por qué elegir LaBen Sistema Comercial?
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                Simple e intuitivo, es fácil de usar.Nuestra infraestructura en la nube te permitirá realizar una puesta en marcha rápida, sin necesidad de conocimientos técnicos y a través de una interfaz amigable.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Escalable
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Puedes cambiar en cualquier momento por el plan que quieras sea superior o inferior sin perden información o dejar de trabajar. 
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Planes flexible
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Puedes elegir el plan que mas se adecue a las características específicas que tu negocio o rubro necesita, agregando o quitando nivel de detalle.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-headset text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Estamos para acompañarte.
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Nuestros equipo te brindará asesoramiento y soporte personalizado de forma constante a través de múltiples canales.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Contratar
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete este formulario y nos comunicaremos con usted en 24 horas.
                  </p>
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Nombre completo
                      <small
                        v-if="nombreValid && nombre === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Nombre y apellido"
                      v-model="nombre"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                      <small
                        v-if="emailValid && email === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <input
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      v-model="email"
                    />
                  </div>

                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Celular
                      <small
                        v-if="celularValid && celular === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Número de celular"
                      v-model="celular"
                    />
                   
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Plan para demostración
                      <small
                        v-if="planSeleccionadoValid && planSeleccionado === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <p
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <span
                        v-for="p in planes" :key="p.id"
                        style="margin-right:2em;"
                      >
                        <input
                          type="radio"
                          v-model="planSeleccionado"
                          v-bind:value="p.id"
                        >
                        <strong style="margin:0.5em">{{ p.nombre }}</strong>
                      </span>
                    </p>
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Comentario
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Escriba un comentario..."
                      v-model="comentario"
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      v-if="!sending"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      v-on:click="send()"
                    >
                      Solicitar plan
                    </button>
                    <div v-else>
                      Enviando solicitud. Aguarde un instante
                      <div class="preloader" style="margin-left: 45%;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="alertOpen"
          style="position: fixed;min-width: 550px;"
          :class="`text-white Alert px-6 py-4 border-0 rounded relative mb-4  ${colorAlert}`"
        >
          <span class="text-xl inline-block mr-5 align-middle">
            <i :class=alertIcono></i>
          </span>
          <span class="inline-block align-middle mr-8">
            <b
              v-for="v in validaMensaje"
              :key="v"
              class="capitalize" style="margin-left:1em;"
            >
              {{ v }}
            </b>
          </span>
          <button style="margin-right:1em;"
            class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" 
            v-on:click="alertOpen = !alertOpen"
          >
            <span>×</span>
          </button>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      PlanUno: {},
      PlanDos: {},
      PlanTres: {},
      planes: [],
      nombre: '',
      email: '',
      celular: '',
      comentario: '',
      planSeleccionado: '',
      nombreValid: false,
      emailValid: false,
      celularValid: false,
      planSeleccionadoValid: false,
      sending: false,
      alertOpen: false,
      alertColor: 'bg-emerald-500',
      alertIcono: 'fas fa-exclamation-triangle'
    };
  },
  created () {
    this.loadData()
  },
  methods: {
    queryAndIndeterminate () {
      setTimeout(() => {
        this.alertOpen = false
      }, 5000)
    },
    send () {
      var me = this
      me.validaMensaje = []
      me.sending = true
      var invalido = false
      if (me.nombre === '') {
        me.nombreValid = true
        invalido = true
      }
      if (me.email === '') {
        me.emailValid = true
        invalido = true
      }
      if (me.celular === '') {
        me.celularValid = true
        invalido = true
      }
      if (me.planSeleccionado === '') {
        me.planSeleccionadoValid = true
        invalido = true
      }
      if (invalido) {
        me.sending = false
        return
      }
      axios.post('api/Mensajes/Enviar', {
        nombre: me.nombre,
        email: me.email,
        celular: me.celular,
        planSeleccionado: me.planSeleccionado,
        message: me.comentario,
      }).then(function (response) {
        if (response.data.includes('error') || response.data.includes('Failed')) {
          me.colorAlert = 'bg-red-500'
          me.validaMensaje.push('Se produjoun error al enviar la solicitud.')
          me.alertIcono ="fas fa-exclamation-triangle"
          me.alertOpen = true
          me.limpiar()
          me.queryAndIndeterminate()
        } else {
          me.colorAlert = 'bg-emerald-500'
          me.validaMensaje.push('Solicitud enviada correctamente.')
          me.alertIcono ="fas fa-thumbs-up"
          me.alertOpen = true
          me.limpiar()
          me.queryAndIndeterminate()
        }
      }).catch(function () {
        me.colorAlert = 'bg-red-500'
        me.validaMensaje.push('Se produjoun error al enviar la solicitud.')
        me.alertIcono ="fas fa-exclamation-triangle"
        me.alertOpen = true
        me.limpiar()
        me.queryAndIndeterminate()
      })
    },
    limpiar () {
      this.nombre = ''
      this.email = ''
      this.celular = ''
      this.comentario = ''
      this.planSeleccionado = ''
      this.nombreValid = false
      this.emailValid = false
      this.celularValid = false
      this.planSeleccionadoValid = false
      this.sending = false
    },
    async loadData() {
      try {
        const response = await fetch('Datos/Planes.json')
        const fileContent = await response.json()
        var planes = fileContent;
        this.planes = planes
        this.PlanUno = planes.find( p => p.id === '1' )
        this.PlanDos = planes.find( p => p.id === '2' )
        this.PlanTres = planes.find( p => p.id === '3' )
      } catch (error) {
        console.error('Error loading JSON data', error);
      }
    },
  },
  
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
<style>
.preloader {
  width: 30px;
  height: 30px;
  border: 3px solid #eee;
  border-top: 3px solid #666;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
