<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
         Mensajes
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Cuenta
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        v-on:click="toggleModal($event)"
      >
        Notificar
      </a>
    </div>
    <div v-if="showModalMsg"
      class="z-9999 modal max-w-580-px overflow-x-hidden overflow-y-auto fixed outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              Notificación {{ title }}
            </h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              v-on:click="toggleModal($event)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="margin-top: 1em;">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      >
                        Titulo
                      </label>
                      <input
                        type="text"
                        class="border-1  text-left px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="titulo"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      >
                        Mensaje
                      </label>
                      <input
                        type="text"
                        class="border-1  text-left px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="mensaje"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      >
                        Importe
                      </label>
                      <input
                        type="text"
                        class="border-1  text-left px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="importe"
                      />
                    </div>
                  </div>
                  <div class="sss">
                    <div class="relative w-full mb-3">
                      <a
                        class="text-blueGray-500 py-1 px-3"
                        href="#tipo"
                        ref="btnDropdownRef"
                        v-on:click="toggleDropdownTipo($event)"
                      >
                        Tipo {{ tipo }}
                        <i class="fas fa-ellipsis-v"></i>
                      </a>
                      <div
                        ref="popoverDropdownRef"
                        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                        v-bind:class="{
                          hidden: !dropdownTipoShow,
                          block: dropdownTipoShow,
                        }"
                      >
                        <button
                          class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          @click="tipo = 'Aviso'"
                        >
                          Aviso
                        </button>
                        <button
                          class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          @click="tipo = 'Alerta'"
                        >
                          Alerta
                        </button>
                        <button
                          class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          @click="tipo = 'Error'"
                        >
                          Error
                        </button>
                        <button
                          class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          @click="tipo = 'Importante'"
                        >
                          Importante
                        </button>
                        <button
                          class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          @click="tipo = 'Urgente'"
                        >
                          Urgente
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="mt-6 border-b-1 border-blueGray-300" />
              </form>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleModal($event)"
            >
              Cerrar
            </button>
            <button
              v-if="server !== ''"
              class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="send()"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalMsg" class="modal-overlay z-9998">
    </div>
    <div
      v-if="snackbar"
      style="position: fixed;
              left: 50%;
              bottom: 20px;
              width: 500px;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              text-align: left;
              z-index: 9999"
       :class="`text-white px-6 py-4 border-0 rounded relative mb-4 ${colorAviso}`">
      <h1>
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-exclamation-triangle "></i>
        </span>
        <strong class="inline-block align-middle mr-8" style="margin-left: 2em;text-align: left;">Aviso</strong>
      </h1>
      <hr style="margin:0.5em;" />
      <p
        v-for="v in validaMensaje"
        :key="v"
      >
        <b style="margin-left:1em; text-align: left;">
          <small>
            {{ v }}
          </small>
        </b>
      </p>
      <button style="margin-right:1em;" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="snackbar = !snackbar">
        <span>×</span>
      </button>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import axios from 'axios'

export default {
  props: {
    cliente: { 
      tpe: String,
      default: ""
    },
    server: { 
      tpe: String,
      default: ""
    }
  },
  data() {
    return {
      titulo: '',
      mensaje: '',
      importe: '',
      tipo: '',
      model: '',
      progressBar: 0,
      loading: false,
      showModalMsg: false,
      dropdownPopoverShow: false,
      dropdownTipoShow: false,
      colorAviso: 'bg-red-500',
      snackbar: false,
      validaMensaje: [],
    };
  },
  created () {
  },
  methods: {
    toggleModal: function (event) {
      event.preventDefault();
      this.showModalMsg = !this.showModalMsg;
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
    },
    toggleDropdownTipo: function (event) {
      if (this.dropdownTipoShow) {
        this.tipo = event
        this.dropdownTipoShow = false;
      } else {
        this.dropdownTipoShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    send () {
      axios.defaults.baseURL = this.server
      var me = this
      me.validaMensaje = []
      if (me.titulo === '') {
        me.validaMensaje.push('Falta titulo')
      }
      if (me.tipo === '') {
        me.validaMensaje.push('Falta Tipo')
      }
      if (me.mensaje === '') {
        me.validaMensaje.push('Falta mensaje')
      }
      if (me.importe === '') {
        me.validaMensaje.push('Falta importe')
      }
      if (me.validaMensaje.length > 0) {
        me.snackbar = true
        me.snackbarColor = 'error'
        me.loading = false
        me.showModalMsg = !me.showModalMsg;
        return
      }
      var header = { Authorization: 'Bearer ' + this.$store.state.token }
      var configuracion = { headers: header }
      axios.post('api/Mensaje/Nuevo', {
        titulo: me.titulo,
        tipo: me.tipo,
        message: me.mensaje,
        importe: parseFloat(me.importe),
      }, configuracion).then(function (response) { 
        me.model = response.data
        me.loading = false
        me.showModalMsg = !me.showModalMsg
      }).catch(function (error) {
        me.validaMensaje.push(error)
        me.snackbar = true
        me.snackbarColor = 'error'
        me.loading = false
        me.showModalMsg = !me.showModalMsg
      })
    },
  },
};
</script>
