<template>
  <div>
    <a
      class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
      href="#pablo"
      ref="btnDropdownRef"
      v-if="this.$store.state.usuario !== null"
      v-on:click="toggleDropdown($event)"
    >
      {{ this.$store.state.usuario.nombre }}
    </a>
    <a
      class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
      href="#pablo"
      ref="btnDropdownRef"
      v-else
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-sign-in-alt" style="margin-right:0.5em;"></i> Ingresar
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol === 'Developed'"
        to="/admin/dashboard"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Dashboard
      </router-link>
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol === 'Developed'"
        to="/admin/hosting"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Hosting
      </router-link>
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol === 'Developed'"
        to="/admin/Clientes"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Clientes
      </router-link>
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol === 'Developed'"
        to="/admin/Planes"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Planes
      </router-link>
      <router-link
        v-if="this.$store.state.usuario === null"
        to="/auth/login"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        <i class="fas fa-sign-in-alt" style="margin-right:0.5em;"></i> Ingresar
      </router-link>
      <router-link
        v-if="this.$store.state.usuario === null"
        to="/auth/register"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        <i class="fas fa-user-plus"></i> Registrarse
      </router-link>
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol !== 'Developed'"
        to="/landing"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Landing
      </router-link>
      <router-link
        v-if="this.$store.state.usuario !== null && this.$store.state.usuario.rol !== 'Developed'"
        to="/profile"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Profile
      </router-link>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      user: 0,
      dropdownPopoverShow: false,
    };
  },
  computed: {

  },
  created () {

  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
