<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <img
          class="max-w-full w-16 mx-auto p-2"
          src="../../assets/img/logoBlanco.png"
        />
      </div>
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          to="/"
        >
          Laben Sistemas
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
        :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">

          <li class="flex items-center">
            <button
              class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleModal()"
            >
              <i class="fas fa-arrow-alt-circle-down"></i> Solicitar Demo
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="showModal"
          class="shadow-2xl z-9999 modal max-w-580-px overflow-x-hidden overflow-y-auto fixed outline-none focus:outline-none justify-center items-center flex"
        >
          <div class="relative w-auto my-6 mx-auto max-w-3xl">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--header-->
              <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h4 class="text-2xl font-semibold">
                  Demostración gratuita
                </h4>
               
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
                  <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <!--body-->
              <div class="relative p-6 flex-auto">
                <div class="flex-auto p-5">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Nombre completo
                      <small
                        v-if="nombreValid && nombre === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Nombre y apellido"
                      v-model="nombre"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                      <small
                        v-if="emailValid && email === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <input
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      v-model="email"
                    />
                  </div>

                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Celular
                      <small
                        v-if="celularValid && celular === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Número de celular"
                       v-model="celular"
                    />
                   
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Plan para demostración
                      <small
                        v-if="planSeleccionadoValid && planSeleccionado === ''"
                        class="text-red-500 text-xs mb-2"
                        htmlFor="full-name"
                      >
                        * campo requerido
                      </small>
                    </label>
                    <p
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <span
                        v-for="p in planes" :key="p.id"
                        style="margin-right:2em;"
                      >
                        <input
                          type="radio"
                          v-model="planSeleccionado"
                          v-bind:value="p.id"
                        >
                        <strong style="margin:0.5em">{{ p.nombre }}</strong>
                      </span>
                    </p>
                  </div>
                  
                </div>
              </div>
              <!--footer-->
              <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <div class="text-center mt-6">
                    <button
                      v-if="!sending"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      v-on:click="send()"
                    >
                      Enviar
                    </button>
                    <button
                      v-if="!sending"
                      class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      v-on:click="toggleModal()"
                    >
                      Cerrar
                    </button>
                    <div v-if="sending" style="margin-left: -50%;">
                      Enviando solicitud. Aguarde un instante
                      <div class="preloader" style="margin-left: 50%;margin-bottom:5%"></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
    </div>
    <div v-if="alertOpen"
      style="position: fixed;"
      :class="`text-white Alert px-6 py-4 border-0 rounded relative mb-4  ${alertColor}`"
    >
      <span class="text-xl inline-block mr-5 align-middle">
        <i :class=alertIcono></i>
      </span>
      <span class="inline-block align-middle mr-8">
        <b
          v-for="v in validaMensaje"
          :key="v"
          class="capitalize" style="margin-left:1em;"
        >
          {{ v }}
        </b>
      </span>
      <button style="margin-right:1em;"
        class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" 
        v-on:click="alertOpen = !alertOpen"
      >
        <span>×</span>
      </button>
    </div>
  </nav>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      checked: false,
      showModal: false,
      navbarOpen: false,
      planes: [],
      nombre: '',
      email: '',
      celular: '',
      planSeleccionado: '',
      nombreValid: false,
      emailValid: false,
      celularValid: false,
      planSeleccionadoValid: false,
      sending: false,
      alertOpen: false,
      alertColor: 'bg-emerald-500',
      alertIcono: 'fas fa-exclamation-triangle'
    };
  },
  created () {
    this.loadData()
  },
  methods: {
    queryAndIndeterminate () {
      setTimeout(() => {
        this.alertOpen = false
      }, 5000)
    },
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    toggleModal: function(){
      this.nombre = ''
      this.email = ''
      this.celular = ''
      this.planSeleccionado = ''
      this.nombreValid = false
      this.emailValid = false
      this.celularValid = false
      this.planSeleccionadoValid = false
      this.showModal = !this.showModal;
    },
    async loadData() {
      try {
        const response = await fetch('Datos/HostingModel.json')
        const fileContent = await response.json()
        this.planes = fileContent;
      } catch (error) {
        console.error('Error loading JSON data', error);
      }
    },
    send () {
      var me = this
      me.validaMensaje = []
      me.sending = true
      var invalido = false
      if (me.nombre === '') {
        me.nombreValid = true
        invalido = true
      }
      if (me.email === '') {
        me.emailValid = true
        invalido = true
      }
      if (me.celular === '') {
        me.celularValid = true
        invalido = true
      }
      if (me.planSeleccionado === '') {
        me.planSeleccionadoValid = true
        invalido = true
      }
      if (invalido) {
        me.sending = false
        return
      }
      var header = { Authorization: 'Bearer ' + me.$store.state.token }
      var configuracion = { headers: header }
      axios.post('api/Mensaje/Enviar', {
        nombre: me.nombre,
        email: me.email,
        celular: me.celular,
        planSeleccionado: me.planSeleccionado,
        message: 'Solicitar Demo',
      }, configuracion).then(function (response) {
        if (response.data.includes('Error')) {
          me.colorAlert = 'bg-red-500'
          me.validaMensaje.push('Se produjoun error al enviar la solicitud.')
          me.alertIcono ="fas fa-exclamation-triangle"
          me.alertOpen = true
          me.queryAndIndeterminate()
        } else {
          me.colorAlert = 'bg-emerald-500'
          me.validaMensaje.push('Solicitud enviada correctamente.')
          me.alertIcono ="fas fa-thumbs-up"
          me.alertOpen = true
          me.limpiar()
          me.queryAndIndeterminate()
        }
      }).catch(function (error) {
        me.colorAlert = 'bg-red-500'
        me.validaMensaje.push(error)
        me.alertIcono ="fas fa-exclamation-triangle"
        me.alertOpen = true
        me.queryAndIndeterminate()
      })
    },
    limpiar () {
      this.nombre = ''
      this.email = ''
      this.celular = ''
      this.planSeleccionado = ''
      this.nombreValid = false
      this.emailValid = false
      this.celularValid = false
      this.planSeleccionadoValid = false
      this.sending = false
      this.toggleModal()
    },
  },
  components: {

  },
};
</script>
<style>
.preloader {
  width: 30px;
  height: 30px;
  border: 3px solid #eee;
  border-top: 3px solid #666;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
