<template>
  <div>
    
    <index-navbar />
    <section
      class="header relative pt-16 items-center flex h-screen max-h-860-px"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-32 sm:pt-0">
            <h2 class="font-semibold text-4xl text-blueGray-600">
              LaBen - Sistema Comercial
            </h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              Automatiza tus tareas administrativas con:
              <span class="text-blueGray-600">Facturación electrónica</span>, 
              <span class="text-blueGray-600">Control de stock</span>, 
              <span class="text-blueGray-600">Cuentas Corrientes de clientes y proveedores</span>,
              <span class="text-blueGray-600">Reportes</span>, y mucho más.
            </p>
            <div class="mt-12">
              <button 
                class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Más información
              </button >
            </div>
          </div>
        </div>
        <div v-if="showModal"
          class="z-9999 modal max-w-580-px overflow-x-hidden overflow-y-auto fixed outline-none focus:outline-none justify-center items-center flex"
        >
          <div class="relative w-auto my-6 mx-auto max-w-3xl">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--header-->
              <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 class="text-3xl font-semibold">
                  LaBen Sistema comercial
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
                  <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <!--body-->
              <div class="relative p-6 flex-auto">
                <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                  LaBen Sistema comercial es un Software que integra toda la gestión de tu empresa de forma simple, obteniendo excelentes resultados a corto plazo.
                  Adaptado a todas las plataformas tanto en PC como dispositivos móviles.<br/>
                  <strong>Sin instalaciones</strong><br/>Al ser 100% Cloud, no tienes que preocuparte por instalaciones, hardware ni infraestructura.
                </p>
              </div>
              <!--footer-->
              <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px"
        :src="patternVue"
        alt="..."
      />
      
    </section>

    <section class="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div
            class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
          >
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
            >
              <img
                alt="..."
                src="../assets/img/photo-1.jpg"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-emerald-500 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Sistema de módulos
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  Adaptado a todas las plataformas tanto en PC como móviles, Laben Sistema Comercial es un software on-line disponible en todo momento en el lugar donde este, con el dispositivo que tenga. Comodidad, accesibilidad y control es lo que ofrece este software de Venta y control de Stock
                  <br/><br/>
                  A continuación te detallamos algunos de los módulos más importantes.
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-boxes"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Control de Stock
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Administra todo el stock mediante un sencillo pero sofisticado Módulo de Stock por productos y categoría.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-closed-captioning"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Cuentas Corrientes
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      El sistema cuenta con un Módulo de Cuenta Corriente para Clientes y otro para Proveedores para llevar las cuentas al día.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col min-w-0 mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-cash-register"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Caja y Valores
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Control de ingresos y egresos con el Módulo de Caja. Administra Valores propios o de terceros, y genera boletas de depósito.
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-shopping-cart"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Facturación Electrónica
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Integración de facturas electrónicas. Envío de Facturas por Email o imprimir la factura o ticket avaladas por AFIP.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mx-auto overflow-hidden pb-20">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-store"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Sucursales
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              Integra todas tus sucursales en un mismo sistema. 
              Laben Sistema Comercial te ofrece gestionar todas las sucursales de tu negocio haciendo uso de su respectivo stock y ventas.
            </p>
            <div class="block pb-6">
              <h6 style="color: #667eea">Tareas independiente por cada sucursal</h6>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Ventas
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Stock
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Caja y Gastos
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Reportes
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Valores
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Facturación electrónica
              </span>
            </div>
            <div class="block pb-6">
              <h6 style="color: #667eea">Tareas unificadas entre sucursales</h6>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Clientes
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Proveedores
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Categoría y Productos
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Precios
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Cuenta Corriente
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Anticipos de cliente
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Reportes globales
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Usuarios
              </span>
            </div>
          </div>

          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
            <div
              class="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0"
            >
              <img
                alt="..."
                :src="componentProfileCard"
                class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px"
              />
              <img
                alt="..."
                :src="componentInfoCard"
                class="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px left-40-px -top-225-px z-2"
              />
              <img
                alt="..."
                :src="componentInfo2"
                class="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
              />
              <img
                alt="..."
                :src="componentBtnPink"
                class="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center pt-32">
          <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
            <div class="justify-center flex flex-wrap relative">
              <div class="my-4 w-full lg:w-6/12 px-4">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div class="bg-red-600 shadow-lg rounded-lg text-center p-8">
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/dash.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Dashboard
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/Venta.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Ventas
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/Productos.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Productos
                    </p>
                  </div>
                </a>
              </div>
              <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/js/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-yellow-500 shadow-lg rounded-lg text-center p-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/Stock.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Stock
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/angular/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/comprobante.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comprobante
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vn-index"
                  target="_blank"
                >
                  <div
                    class="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md max-w-full mx-auto p-1 bg-white"
                      src="../assets/img/cc.jpg"
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Cuenta Corriente
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-desktop"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Interfaz Amigable
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              La sencillez de las pantallas y la homogeneidad de todo el producto, otorga un aprendizaje rápido al manejo de sus módulos.
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              El sistema es de fácil manejo para cualquiera que tenga idea de trabajo de oficinas, muy bueno. Se ajusta siempre a las necesidades de los usuarios, lo cual es una gran ventaja
            </p>
            <button
              class="text-black hover:text-black background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleScrenModal()"
            >
              Mostrar más
              <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="container mx-auto px-4 pb-32 pt-48">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-cloud"></i>
              </div>
              <h3 class="text-3xl font-semibold">
                100% Cloud
              </h3>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                Al ser 100% Cloud, no tienes que preocuparte por instalaciones, hardware ni infraestructura. 
                <br/>Gestiona tu empresa de forma on-line
                <span class="text-blueGray-600">cómo</span>,
                <span class="text-blueGray-600">cuándo</span> y desde 
                <span class="text-blueGray-600">desde dónde quieras.</span>
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                    <span class="text-blueGray-600" style="color: #667eea">Ventajas de sistemas Cloud</span>
                  </p>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-laptop-code"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        No necesita Instalación. Solo accedes desde tu navegador de internet favorito.
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-couch"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Acceso al sistema cuando, y donde quieras bajo un seguro sistema de autenticación.
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-cogs"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Asistencia de soporte técnico las 24hs de día y actualizaciones sin interrumpir su trabajo.
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-user-friends"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Múltiples usuarios conectados al mismo tiempo realizando sus diversas tareas.
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-tachometer-alt"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Máxima velocidad de respuesta en procesamiento de datos y búsqueda de información.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              :src="documentation"
            />
          </div>
        </div>
      </div>

      <div class="justify-center text-center flex flex-wrap mt-24">
        <div class="w-full md:w-6/12 px-12 md:px-4">
          <h2 class="font-semibold text-4xl">
            Planes
          </h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Costos muy accesible. Explora los diversos planes disponibles que más se adapten a la necesidad de su comercio o negocio.
          </p>
        </div>
      </div>

      <div v-if="showScreenModal"
        class="z-9999 modal max-w-800-px fixed outline-none focus:outline-none justify-center items-center flex"
      >
        <div class="relative w-auto my-6 mx-auto max-w-3xl">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-3xl font-semibold">
                Interfaz
              </h3>
              <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              v-on:click="toggleScrenModal()">
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6 flex-auto modalOverflow">
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Dashboard
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/dash-800.jpg"
                />
              </p>
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Consulta de Stock
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/stock-800.jpg"
                />
              </p>
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Venta
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/venta-800.jpg"
                />
              </p>
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Muestra de comprobante Factura
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/comprobante-800.jpg"
                />
              </p>
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Listado de productos
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/producto-800.jpg"
                />
              </p>
              <p class="my-4 text-blueGray-500 text-lg leading-relaxed p-4">
                Cuenta Corriente de clientes
                <img
                  alt="..."
                  class="shadow-md max-w-full mx-auto p-1 bg-white"
                  src="../assets/img/cc-800.jpg"
                />
              </p>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleScrenModal()">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="block relative z-1 bg-blueGray-600">
      <div class="container mx-auto">
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 -mt-24">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  {{ PlanUno.nombre}}
                </h5>
                <router-link to="/contratar">
                  <div
                    class="divPlanes text-center text-white text-5xl p-8 Fondo-BlueBlack hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <i :class="`${PlanUno.icono}`"></i>
                    <p class="m-8 ">
                      <small class="text-2xl">$ </small>
                      <strong class="text-4xl">{{ PlanUno.precio }}</strong>
                    </p>
                    <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanUno.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </router-link>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  {{ PlanDos.nombre }}
                </h5>
                <router-link to="/contratar">
                  <div
                    class="divPlanes text-center text-white text-5xl p-8 Fondo-BlueBlack hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <i :class="`${PlanDos.icono}`"></i>
                    <p class="m-8 ">
                      <small class="text-2xl">$ </small>
                      <strong class="text-4xl">{{ PlanDos.precio }}</strong>
                    </p>
                    <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanDos.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </router-link>
              </div>

              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center">
                  {{ PlanTres.nombre }}
                </h5>
                <router-link to="/contratar">
                  <div
                    class="divPlanes text-center text-white text-5xl p-8 Fondo-Red hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <i :class="`${PlanTres.icono}`"></i>
                    <p class="m-8 ">
                      <small class="text-2xl">$ </small>
                      <strong class="text-4xl">{{ PlanTres.precio }}</strong>
                    </p>
                    <ul class="text-lg text-left" style="padding-left:4em;">
                      <li v-for="item in PlanTres.items" :key="item.name">
                        <small v-if="item.value === 'Si'">
                          <i class="fas fa-check" style="color: #3bd38a"></i>
                        </small>
                        <small v-else>
                          <i class="fas fa-times" style="color: #e43358"></i>
                        </small>
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-20 bg-blueGray-600 overflow-hidden">
      <div class="container mx-auto pb-64">
        <div class="flex flex-wrap justify-center">
          <div class="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-ruler-combined text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal text-white">
              Desarrollos a medida
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400"
            >
              El
              <a
                class="text-blueGray-300"
              >
                desarrollo de software a medida
              </a>
              es el servicio de diseño y creación de herramientas informáticas basadas en necesidades particulares de la empresa o negocio que lo contrata.<br>
              <a class="text-blueGray-300">LaBen Sistema Comercial</a> es totalmente flexible para adaptarse a su forma de trabajo.
            </p>
            <hr>
            <strong class="text-2xl font-light leading-relaxed mt-4 mb-4 text-blueGray-200">Beneficios de un software a medida</strong>
            <ul class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400">
              <li>
                <a class="text-blueGray-300">Control y precisión</a> en las distintas áreas claves del negocio que mejoran el rendimiento.
              </li>
              <li>
                <a class="text-blueGray-300">Personalización</a> de la herramienta de la forma más conveniente e intuitiva para la empresa.
              </li>
              <li>
                <a class="text-blueGray-300">Adaptabilidad y velocidad</a> de reacción frente a los cambios.
              </li>
              <li>
                <a class="text-blueGray-300">Menor tiempo</a> de aprendizaje de uso de la herramienta.
              </li>
              <li>
                <a class="text-blueGray-300">Dedicación exclusiva</a> de un equipo a la mejora continua.
              </li>
              <li>
                <a class="text-blueGray-300">Atención</a> al cliente personalizada.
              </li>
            </ul>
            <p
              class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-400"
            >
              Puedes registrarte y solicitar asesoramiento sobre tu software a medida.
            </p>
            <router-link
              v-if="this.$store.state.usuario === null"
              to="/auth/register"
              class="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              <i class="fas fa-user-plus"></i> Registrate
            </router-link>
          </div>

          <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
            <i
              class="fas fa-pencil-ruler text-blueGray-700 text-35 absolute -top-20-px -right-0 left-auto opacity-80"
            ></i>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-16 bg-blueGray-200 relative pt-32">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <div
          class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10"
        >
          <div class="w-full text-center lg:w-8/12">
            <p class="text-4xl text-center">
              <span role="img" aria-label="love">
                😍
              </span>
            </p>
            <h3 class="font-semibold text-3xl">
              ¿Te gustó LaBen Gestión Comercial?
            </h3>
            <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
              Puedes contratar el servicio en cualquier momento. Solo debes elegir el plan que más se adapte a tu negocio
              y recibirás una demostración sin cargo para ver el alcance que tiene nuestro software.
            </p>
            <div class="sm:block flex flex-col mt-10">
              <router-link
                v-if="this.$store.state.usuario === null"
                to="/contratar"
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                contratar
              </router-link>
            </div>
            <div class="text-center mt-16"></div>
          </div>
        </div>
      </div>          
    </section>
    <footer-component />

    <div v-if="showModal || showScreenModal" class="modal-overlay z-9998"></div>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import fondo from "@/assets/img/fondo.jpg";
import fondoRed from "@/assets/img/fondoRed.jpg";

export default {
  data() {
   return {
      showModal: false,
      showScreenModal: false,
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      fondo,
      fondoRed,
      PlanUno: {},
      PlanDos: {},
      PlanTres: {},
      items: [{ id: 1, detalle: 'Foo' }, { id: 2, detalle: 'Bar' }]
    };
  },

  components: {
    IndexNavbar,
    FooterComponent,
  },
  created () {
    this.loadData()
    },
  methods: {
    toggleModal: function(){
      this.showModal = !this.showModal;
    },
    toggleScrenModal: function(){
      this.showScreenModal = !this.showScreenModal;
    },
    async loadData() {
      try {
        const response = await fetch('Datos/Planes.json')
        const fileContent = await response.json()
        var planes = fileContent;
        this.PlanUno = planes.find( p => p.id === '1' )
        this.PlanDos = planes.find( p => p.id === '2' )
        this.PlanTres = planes.find( p => p.id === '3' )
      } catch (error) {
        console.error('Error loading JSON data', error);
      }
    },
  }
};
</script>
