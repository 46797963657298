import Vuex from 'vuex'
import router from './router'
import decode from 'jwt-decode'

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    userId: null,
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setUsuario (state, usuario) {
      state.usuario = usuario
    },
  },
  actions: {
    guardarToken ({ commit }, token) {
      commit('setToken', token)
      commit('setUsuario', decode(token))
      localStorage.setItem('token', token)
    },
    autoLogin ({ commit }, routerName) {
      var token = localStorage.getItem('token')
      if (token) {
        commit('setToken', token)
        commit('setUsuario', decode(token))
      }
      router.push({ name: routerName })
    },
    salir ({ commit }) {
      commit('setToken', null)
      commit('setUsuario', null)
      localStorage.removeItem('token')
      router.push({ name: 'Login' })
    },
    forgotPassword ({ commit }) {
      commit('setToken', null)
      commit('setUsuario', null)
      localStorage.removeItem('token')
      router.push({ name: 'ForgotPassword' })
    },
  }
})
