<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Cuentas de Hosting
        </h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          v-on:click="toggleModal()"
        >
          Nueva cuenta
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form
        v-for="v in model"
        :key="v.nombre"
      >
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ v.nombre }}
          <button class="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
            <i class="fas fa-pen-square"></i>
          </button>
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Dominio
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.dominio"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Dominio alternativo
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.alternativo"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Usuario
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.usuario"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Contraseña
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.pass"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                FTP Sistema
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.ftpSis"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                FTP Web Servicio API
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :value="v.ftpApi"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <a
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                :value="v.PanelFerozo"
                target="_blank"
              >
                Panel Ferozo
              </a>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalApi()"
              >
                Datos del servicio
              </button>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />
      </form>
    </div>
    <div v-if="showModalApi"
      class="z-9999 modal max-w-580-px overflow-x-hidden overflow-y-auto fixed outline-none focus:outline-none justify-center items-center flex"
    >

    </div>
    <div v-if="showModal"
      class="z-9999 modal max-w-580-px overflow-x-hidden overflow-y-auto fixed outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              Agregar nuevo Hosting
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="margin-top: 1em;">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nombre
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="nombre"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        dominio
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="dominio"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Usuario
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="usuario"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Usuario Api
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="usuarioApi"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Contraseña
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        v-model="clave"
                      />
                    </div>
                  </div>
                </div>

                <hr class="mt-6 border-b-1 border-blueGray-300" />
              </form>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
              Cerrar
            </button>
             <button
                class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="nuevo()"
              >
              Guardar
            </button>
          </div>
          
        </div>
      </div>
    </div>
    <div v-if="showModal || showScreenModal" class="modal-overlay z-9998"></div>
    <div
      v-if="alertOpen"
      style="position: fixed;
              left: 50%;
              bottom: 20px;
              width: 500px;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              z-index: 9999"
       :class="`text-white px-6 py-4 border-0 rounded relative mb-4 ${colorAviso}`">
      <h1>
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-exclamation-triangle "></i>
        </span>
        <strong class="inline-block align-middle mr-8" style="margin-left: 2em;">Aviso</strong>
      </h1>
      <hr style="margin:0.5em;" />
      <p
        v-for="v in validaMensaje"
        :key="v"
      >
        <b style="margin-left:1em;">
          <small>
            {{ v }}
          </small>
        </b>
      </p>
      <button style="margin-right:1em;" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="closeAlert()">
        <span>×</span>
      </button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data: () => ({
      showModal: false,
      showModalApi: false,
      loading: false,
      saving: false,
      dialog: false,
      validaMensaje: [],
      colorAviso: 'bg-red-500',
      alertOpen: false,
      model: [],
      nombre: '',
      dominio: '',
      usuario: '',
      usuarioApi: '',
      clave: '',
    }),
    created () {
      this.loadData()
      this.listar()
    },
    methods: {
      toggleModal: function(){
        this.showModal = !this.showModal;
      },
      toggleModalApi: function(){
        this.showModalApi = !this.showModalApi;
      },
      closeAlert: function(){
        this.alertOpen = false;
      },
      async loadData() {
        try {
          const response = await this.$axios.get('/Datos/HostingModel.json');
          this.model = response.data;
        } catch (error) {
          console.error('Error loading JSON data', error);
        }
      },
      listar () {
        var me = this
        me.loading = true
        var header = { Authorization: 'Bearer ' + this.$store.state.token }
        var configuracion = { headers: header }
        axios.get('api/Hosting/Listar', configuracion).then(function (response) {
          me.model = response.data
          me.loading = false
        }).catch(function (error) {
          me.validaMensaje.push(error)
          me.snackbar = true
          me.snackbarColor = 'error'
          me.loading = false
        })
      },
      nuevo () {
        var me = this
        me.saving = true
        me.validaMensaje = []
        if (me.nombre == '') {
          me.validaMensaje.push('El nombre es obligatorio')
        }
        if (me.dominio == '') {
          me.validaMensaje.push('El dominio es obligatorio')
        }
        if (me.usuario == '') {
          me.validaMensaje.push('El usuario es obligatoria')
        }
        if (me.clave == '') {
          me.validaMensaje.push('La clave es obligatoria')
        }
        if (me.validaMensaje.length > 0) {
          me.saving = false
          me.alertOpen = true
          me.colorAviso = 'bg-red-500'
          me.esError = true
          return
        }
        axios.post('api/Hosting/Crear', {
          nombre: me.nombre,
          usuario: me.usuario,
          pass: me.clave,
          dominio: me.dominio,
          usuarioApi: me.usuarioApi,
        }).then(respuesta => {
          return respuesta.data
        }).then(function () {
          me.validaMensaje.push('Correcto')
          me.alertOpen = true
          me.colorAviso = 'bg-green'
          me.esError = false
          me.saving = false
        }).catch(function (error) {
          me.validaMensaje.push(error)
          me.alertOpen = true
          me.colorAviso = 'bg-red-500'
          me.esError = true
          me.saving = false
          me.closeAlert()
        })
      },
    },
  }
</script>
