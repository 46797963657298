<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Registrarse</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nombre
                </label>
                <input
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Name"
                  v-model="nombre"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-model="email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Contraseña"
                  v-model="password"
                />
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    v-model="iagree"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Estoy de acuerdo con la
                    <a href="javascript:void(0)" class="text-emerald-500">
                      Política de privacidad
                    </a>
                  </span>
                </label>
              </div>

              <div v-if="!loadingPass" class="text-center mt-6">
                <button
                  v-if="iagree && nombre !== '' && email !== '' && password !== ''"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                  @click="register()"
                >
                  Crear cuenta
                </button>
                <button
                  v-else
                  :disabled="true"
                  class="bg-blueGray-200 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Crear cuenta
                </button>
              </div>
              <div v-if="loadingPass" class="preloader"></div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
             <router-link to="/Contratar" class="text-blueGray-200">
              <small>Contratar LaBen Sistema Comercial</small>
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/login" class="text-blueGray-200">
              <small>Ingresar a su cuenta</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="alertOpen"
      style="position: fixed;
              left: 50%;
              bottom: 20px;
              width: 500px;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              z-index: 9999"
       :class="`text-white px-6 py-4 border-0 rounded relative mb-4 ${colorAviso}`">
      <h1>
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-exclamation-triangle "></i>
        </span>
        <strong class="inline-block align-middle mr-8" style="margin-left: 2em;">Aviso</strong>
      </h1>
      <hr style="margin:0.5em;" />
      <p
        v-for="v in validaMensaje"
        :key="v"
      >
        <b style="margin-left:1em;">
          <small>
            {{ v }}
          </small>
        </b>
      </p>
      <button style="margin-right:1em;" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="closeAlert()">
        <span>×</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      nombre: '',
      email: '',
      password: '',
      colorAviso: 'bg-red-500',
      iagree: false,
      loadingPass: false,
      alertOpen: false,
      value: 50,
      validaMensaje: [],
    };
  },
  created () {

  },
  methods: {
    queryAndIndeterminate () {
      setTimeout(() => {
        this.alertOpen = false
      }, 5000)
    },
    register () {
      var me = this
      me.loadingPass = true
      me.validaMensaje = []
      if (me.nombre == '') {
        me.validaMensaje.push('El nombre es obligatorio')
      }
      if (me.email == '') {
        me.validaMensaje.push('El email es obligatorio')
      }
      if (me.password == '') {
        me.validaMensaje.push('La clave es obligatoria')
      }
      if (me.validaMensaje.length > 0) {
        me.loadingPass = false
        me.alertOpen = true
        me.queryAndIndeterminate()
        return
      }
      axios.post('api/Autenticacion/register', {
        Email: me.email,
        UserName: me.email,
        Password: me.password,
        Nombre: me.nombre,
        Apellido: 'N/A',
      }).then(respuesta => {
        return respuesta.data
      }).then(function () {
        me.validaMensaje.push('Correcto')
        me.alertOpen = true
        me.colorAviso = 'bg-green'
        me.esError = false
        me.loadingPass = false
        me.email = ''
        me.password = ''
        me.nombre = ''
      }).catch(function (error) {
        me.validaMensaje.push(error.response.data)
        me.alertOpen = true
        me.colorAviso = 'bg-red-500'
        me.esError = true
        me.loadingPass = false
      })
    },
    closeAlert: function(){
      this.alertOpen = false;
    }
  },
};
</script>
