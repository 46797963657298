<template>
  <div class="flex flex-wrap mt-4">
    <div v-if="clientes.length > 0" class="w-full mb-12 px-4">
      <card-table :cli = clientes />
    </div>
    <div
      v-if="alertOpen"
      style="position: fixed;
              left: 50%;
              bottom: 20px;
              width: 500px;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              z-index: 9999"
       :class="`text-white px-6 py-4 border-0 rounded relative mb-4 ${colorAviso}`">
      <h1>
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-exclamation-triangle "></i>
        </span>
        <strong class="inline-block align-middle mr-8" style="margin-left: 2em;">Aviso</strong>
      </h1>
      <hr style="margin:0.5em;" />
      <p
        v-for="v in validaMensaje"
        :key="v"
      >
        <b style="margin-left:1em;">
          <small>
            {{ v }}
          </small>
        </b>
      </p>
      <button style="margin-right:1em;" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="closeAlert()">
        <span>×</span>
      </button>
    </div>
  </div>
</template>
<script>
import CardTable from "@/components/Cards/CardClientes.vue";
import axios from 'axios'

export default {
  data() {
    return {
      clientes: [],
      validaMensaje: [],
      alertOpen: false,
      colorAviso: '',
      loading: false,
    };
  },
  components: {
    CardTable
  },
  created () {
    this.ListarClientes()
  },
  methods: {
    ListarClientes () {
      var me = this
      var header = { Authorization: 'Bearer ' + this.$store.state.token }
      var configuracion = { headers: header }
      axios.get('api/Cliente', configuracion).then(function (response) { 
        me.clientes = response.data
      }).catch(function (error) {
        me.validaMensaje.push(error.response.data)
        me.alertOpen = true
        me.colorAviso = 'bg-red-500'
        me.loading = false
      })
    },
  },
};
</script>
