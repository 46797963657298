<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-8/12 px-4">
      <CardHosting />
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <CardNewHosting />
    </div>
  </div>
</template>
<script>
import CardHosting from "@/components/Cards/CardHosting.vue";
import CardNewHosting from "@/components/Cards/CardNewHosting.vue";

export default {
  components: {
    CardHosting,
    CardNewHosting,
  },
};
</script>
