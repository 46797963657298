import store from './store'
import { createWebHistory, createRouter } from "vue-router";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Hosting from "@/views/admin/Hosting.vue";
import Clientes from "@/views/admin/Clientes.vue";
import Usuarios from "@/views/admin/Usuarios.vue";
import Mensajes from "@/views/admin/Mensajes.vue";
import Planes from "@/views/admin/Planes.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";
import Contratar from "@/views/Contratar.vue";

// routes

const routes = [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: Admin,
      component: Admin,
      meta: {
        requiresAuth: true,
        developed: true,
      },
      children: [
        {
          path: "/admin/dashboard",
          name: Dashboard,
          component: Dashboard,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
        {
          path: "/admin/hosting",
          name: Hosting,
          component: Hosting,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
        {
          path: "/admin/clientes",
          name: Clientes,
          component: Clientes,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
        {
          path: "/admin/usuarios",
          name: Usuarios,
          component: Usuarios,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
        {
          path: "/admin/mensajes",
          name: Mensajes,
          component: Mensajes,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
        {
          path: "/admin/planes",
          name: Planes,
          component: Planes,
          meta: {
            requiresAuth: true,
            developed: true,
          },
        },
      ],
    },
    {
      path: "/auth",
      redirect: "/auth/login",
      name: Auth,
      component: Auth,
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "/auth/login",
          name: Login,
          component: Login,
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/auth/register",
          name: Register,
          component: Register,
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: "/Contratar",
      name: Contratar,
      component: Contratar,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/profile",
      name: Profile,
      component: Profile,
      meta: {
        requiresAuth: true,
        user: true,
      },
    },
    {
      path: "/",
      name: Index,
      component: Index,
      meta: {
        requiresAuth: false,
      },
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.usuario === null) {
        next({
          path: '/auth/login',
        })
      } else if (store.state.usuario && store.state.usuario.rol === 'Developed') {
        if (to.matched.some(record => record.meta.developed)) {
          next()
        }
      } else if (store.state.usuario && store.state.usuario.rol === 'User') {
        if (to.matched.some(record => record.meta.user)) {
          next()
        }
      } else {
        next()
      }
    } else {
      next()
    }
  })

export default router