<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="CLIENTES"
              :statTitle="model.clientes.total"
              :statArrow="model.clientes.state == 1 ? 'up' : model.clientes.state == -1 ? 'down': 'up'"
              :statPercent="model.clientes.porcentaje"
              :statPercentColor="model.clientes.state == 1 ? 'text-green-500' : model.clientes.state == -1 ? 'text-red-500': 'text-orange-500'"
              statDescripiron=""
              statIconName="fas fa-chart-pie"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="TOTAL INGRESOS"
              :statTitle="model.ingresos.total"
              :statArrow="model.ingresos.state == 1 ? 'up' : model.ingresos.state == -1 ? 'down': 'up'"
              :statPercent="model.ingresos.porcentaje"
              :statPercentColor="model.ingresos.state == 1 ? 'text-green-500' : model.ingresos.state == -1 ? 'text-red-500': 'text-orange-500'"
              statDescripiron="Mensual"
              statIconName="far fa-chart-bar"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="MENSAJES"
              :statTitle="model.mensajes.total"
              :statArrow="model.mensajes.state == 1 ? 'up' : model.mensajes.state == -1 ? 'down': 'up'"
              :statPercent="model.mensajes.porcentaje"
              :statPercentColor="model.mensajes.state == 1 ? 'text-green-500' : model.mensajes.state == -1 ? 'text-red-500': 'text-orange-500'"
              statDescripiron="Sin leer"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="GASTOS"
              :statTitle="model.gastos.total"
              :statArrow="model.gastos.state == 1 ? 'up' : model.gastos.state == -1 ? 'down': 'up'"
              :statPercent="model.gastos.porcentaje"
              :statPercentColor="model.gastos.state == 1 ? 'text-green-500' : model.gastos.state == -1 ? 'text-red-500': 'text-orange-500'"
              statDescripiron="Proveedores"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import axios from 'axios'
export default {
  components: {
    CardStats,
  },
  
  data: () => ({
    model: []
  }),
  created () {
    // this.listar()
    this.model = {
      ingresos: {
        total: '$ ' + 10.50.toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
        porcentaje: 3.48,
        state: 1
      },
      clientes: {
        total: '$ ' + 10.50.toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
        porcentaje: 3.48,
        state: 1
      },
      mensajes: {
        total: '$ ' + 10.50.toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
        porcentaje: 3.48,
        state: 1
      },
      gastos: {
        total: '$ ' + 10.50.toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
        porcentaje: 3.48,
        state: 1
      },
    }
  },
  methods: {
    listar () {
        var me = this
        me.loading = true
        var header = { Authorization: 'Bearer ' + this.$store.state.token }
        var configuracion = { headers: header }
        axios.get('api/Status/Mostrar', configuracion).then(function (response) {
          this.model = response.data
          me.loading = false
        }).catch(function (error) {
          me.validaMensaje.push(error)
          me.snackbar = true
          me.snackbarColor = 'error'
          me.loading = false
        })
      },
  }
};
</script>