import { createApp } from "vue";
import axios from 'axios'
import store from './store'
import router from './router'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

import App from "@/App.vue";

axios.defaults.baseURL = 'https://localhost:5001/'

createApp(App, router, store)
.use(router)
.use(store)
.mount("#app");
