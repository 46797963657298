<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  v-model="userName"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  v-model="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>

              <div v-if="!loadingPass" class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                  @click="login()"
                >
                  Sign In
                </button>
              </div>
              <div v-if="loadingPass" class="preloader"></div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>¿Se te olvidó tu contraseña?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Crear una nueva cuenta</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
   <div
      v-if="alertOpen"
      style="position: fixed;
              left: 50%;
              bottom: 20px;
              width: 500px;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              z-index: 9999"
       :class="`text-white px-6 py-4 border-0 rounded relative mb-4 ${colorAviso}`">
      <h1>
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-exclamation-triangle "></i>
        </span>
        <strong class="inline-block align-middle mr-8" style="margin-left: 2em;">Aviso</strong>
      </h1>
      <hr style="margin:0.5em;" />
      <p
        v-for="v in validaMensaje"
        :key="v"
      >
        <b style="margin-left:1em;">
          <small>
            {{ v }}
          </small>
        </b>
      </p>
      <button style="margin-right:1em;" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" v-on:click="closeAlert()">
        <span>×</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      userName: '',
      password: '',
      colorAviso: 'bg-red-500',
      loadingPass: false,
      alertOpen: false,
      value: 50,
      validaMensaje: [],
    };
  },
  created () {

  },
  methods: {
    queryAndIndeterminate () {
      setTimeout(() => {
        this.alertOpen = false
      }, 5000)
    },
    login () {
      var me = this
      me.loadingPass = true
      me.validaMensaje = []
      axios.post('api/Autenticacion/token', {
        userName: me.userName,
        password: me.password,
      }).then(respuesta => {
        return respuesta.data
      }).then(data => {        
        me.$store.dispatch('guardarToken', data.token)
        if (me.$store.state.usuario.admin === "si") {
          me.$router.push({ path: "/admin/dashboard" })
        } else {
          me.$router.push({ path: "/user/dashboard" })
        } 
        me.colorAviso = 'bg-green'
        me.esError = false
      }).catch(function (error) {
        me.validaMensaje.push(error.response.data)
        me.alertOpen = true
        me.colorAviso = 'bg-red-500'
        me.esError = true
        me.loadingPass = false
      })
    },
    closeAlert: function(){
      this.alertOpen = false;
    }
  },
};
</script>

<style>
.preloader {
  width: 30px;
  height: 30px;
  border: 3px solid #eee;
  border-top: 3px solid #666;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>